import { createSelector } from 'reselect';
import {
  getFeedTitle,
  isExperimentEnabled,
} from '@wix/communities-blog-client-common';
import { OOI_EXPERIMENTS } from '../../../experiments';
import { getViewerAllPostsLabelTranslation } from '../../common/selectors/viewer-all-post-label-translation-selectors';
import { AppState } from '../../common/types';
import { getShowAllPostsCategory } from '../selectors/category-menu-selectors';

const getCategoryMenuWidgetData = (state: AppState) => state.categoryMenuWidget;

const getAllPostsFeedLabel = (state: AppState) =>
  state.tpaSettings.settings?.allPostsFeedLabel;

const isRemoveBlogsThisEnabled = (state: AppState) =>
  isExperimentEnabled(state, OOI_EXPERIMENTS.REMOVE_BLOGS_THIS_OOI);

export const getCategoryMenu = createSelector(
  getCategoryMenuWidgetData,
  getShowAllPostsCategory,
  getFeedTitle,
  getViewerAllPostsLabelTranslation,
  getAllPostsFeedLabel,
  isRemoveBlogsThisEnabled,
  (
    categoryMenu,
    showAllPosts,
    title,
    translatedTitle,
    allPostsFeedLabel,
    removeBlogsThisEnabled,
  ) => {
    const parsedCategoryMenu = {
      ...categoryMenu,
      categoryMenu: [...categoryMenu.categoryMenu],
    };

    if (showAllPosts && categoryMenu.categoryMenu[0].display) {
      parsedCategoryMenu.categoryMenu[0].display.text = removeBlogsThisEnabled
        ? allPostsFeedLabel
        : translatedTitle || title;
    } else {
      parsedCategoryMenu.categoryMenu = categoryMenu.categoryMenu.slice(1);
    }

    return parsedCategoryMenu;
  },
);
